<template>
	<div>
		<Template :title="$t('global.comments')">
			<section class="p-5 py-4">
				<Back />

				<div
					class="mt-2 border bg-white-"
					v-for="(comment, index) in comments"
					v-show="show(index)"
					:key="comment.id"
				>
					<div class="row m-0 py-3 assistance">
						<div class="col-lg-2">
							<div class="text-center">
								<img
									:src="comment?.company?.logo.url"
									class="rounded-circle mb-2 user_image"
									alt=""
								/>
								<h6 class="mt-2 text-5 text-black-">
									{{ comment.company?.name?? "" }}
								</h6>
							</div>
						</div>
						<div class="col-lg-10">
							<div
								class="border shadow-1 rounded bg-white- text-4 text-black- p-3"
							>
								<h6 class="fw-600 text-5 text-alt-">
									{{
										(comment?.createdBy?.firstname?? "") +
										' ' +
										(comment?.createdBy?.lastname?? "")
									}}
								</h6>
								<p class="text-7 mb-0">
									{{ $t('global.write_at') }} :
									{{ $filters.convertDate(comment?.createdAt) }}
								</p>
								<p class="text-6 mb-0 text-justify lh-lg">
									{{ comment?.content }}
								</p>
							</div>
						</div>
						<div v-if="comment?.responses?.length > 0">
							<div class="col-lg-10 offset-lg-2 text-6 text-primary-">
								<i class="uil uil-angle-down text-primary- fs-4"></i>
								{{$t('others.the_responses')}}
							</div>
							<div class="col-lg-10 offset-lg-2 mt-3">
								<div
									class="row"
									v-for="response in comment.responses"
									:key="response.id"
								>
									<div class="col-lg-2">
										<div class="text-center">
											<img
												src="@/assets/images/SANS_FOND_LOGO_NOIR.png"
												class="rounded-circle border user_image_reply"
												alt=""
											/>
										</div>
									</div>
									<div class="col-lg-10">
										<div
											class="border shadow-1 rounded bg-white- text-4 text-black- p-3"
										>
											<h6 class="fw-600 text-5 text-alt-">PRO GESTION SOFT</h6>
											<p class="text-7 mb-0">
												{{ $t('global.write_at') }} :
												{{ $filters.convertDate(response.createdAt) }}
											</p>
											<p class="text-6 mb-0 text-justify lh-lg">
												{{ response.content }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-10 d-none offset-lg-2 text-primary-">
							<i class="uil uil-corner-up-left-alt text-primary- fs-4"></i
							>{{$t('others.reply')}}
						</div>
					</div>
				</div>

				<div class="row g-0" v-if="!loading && comments.length > perPage">
					<nav aria-label="Page navigation" class="mt-3">
						<ul class="pagination justify-content-center">
							<li class="page-item" @click="previous">
								<a class="page-link" href="#" aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
								</a>
							</li>

							<li class="page-item" @click="previous">
								<a class="page-link" href="#">{{$t('global.previous')}}</a>
							</li>
							<small class="mx-3 mt-2"
								>{{ Math.ceil(comments.length / perPage) > 0 ? page : 0 }} /
								{{ Math.ceil(comments.length / perPage) }}</small
							>
							<li class="page-item" @click="next">
								<a class="page-link" href="#">{{$t('global.next')}}</a>
							</li>

							<li class="page-item" @click="next">
								<a class="page-link" href="#" aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>


				<!-- ADD COMMENT -->
				<div class="mt-2" v-if="user?.emailAddress">
					<div class="row m-0 py-3 assistance border bg-white-">
						<div class="col-xl-3">
							<div class="pt-3 text-center">
								<img
									:src="user?.company?.logo.url"
									class="rounded-circle user_image"
									alt=""
								/>
								<h6 class="mt-2 text-5 text-black-">
									{{ (user?.firstname ?? '') + ' ' + (user?.lastname ?? '') }}
								</h6>
								<p class="text-primary- mb-0">{{ user?.company?.name?? "" }}</p>
								<i class="uil uil-message fs-5 text-primary-"></i>
							</div>
						</div>
						<form @submit.prevent="addComment" class="col-xl-9">
							<InputFC
								:error="errors.content"
								:type="'textarea'"
								v-model="content"
								:placeholder="$t('others.write_your_comment')"
							/>
							<SpinnerMe :elementId="'spinner'" />
							<button class="button mt-3" id="submit">
								<i class="uil uil-message fs-6 me-2 text-white-"></i>
								{{ $t('global.send') }}
							</button>
						</form>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import InputFC from '@/components/InputFC.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
import { useUserStore } from '@/store/user';
export default {
	setup() {
		const store = useUserStore();
		return { store };
	},
	name: 'CommentIndex',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			comments: [],
			errors: [],
			user: this.store.user,
			content: '',
			page: 1,
			loading: false,
			perPage: 10,
		};
	},
	methods: {
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.comments.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		addComment() {
			if (Helper.required(this.content) === '') {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);
				let registerFormData = new FormData();
				registerFormData.append('content', this.content);

				fetch(
					Helper.route('admin/comment/add'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => {
						if (response.status === 401) {
							this.ok = false;
						}
						return response.json();
					})
						.then((data) => {
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.content = Helper.getError(data.problems, 'content');
						} else {
							if (!this.ok) {
								this.error_message = data.message;
							} else {
								this.content = '';
								data.data.createdBy = this.store.user;
								this.comments.push(data.data);
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
			}
		},
		getUser() {
			fetch(Helper.route('admin/profile/get-data'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.user = data.data;
					this.store.setUser(this.user);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getComments() {
			this.$isLoading(true);
			fetch(Helper.route('admin/comment/all'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					if (data.data) {
						this.comments = data.data;
					}
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
	created() {
		this.user = this.store.user;
		this.getComments();
		this.getUser();
	},
};
</script>
<style>
.assistance .user_image {
	height: 70px !important;
	width: 70px !important;
}
.assistance .user_image_reply {
	height: 50px !important;
	width: 50px !important;
}
</style>
