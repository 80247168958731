<template>
	<div class="row m-0 mt-3">
		<div class="col-9 hstack p-1 rounded shadow-sm px-2 bg-white-">
			<div class="">
				<router-link
					v-if="uploaded && !deleted"
					:to="{
						name: 'documents-show',
						params: { id: uploaded.reference, slug: uploaded.slug },
					}"
					><h6 class="m-0 text-5 text-black- fw-500">
						{{ file?.name }}
					</h6></router-link
				>
				<h6 v-else class="m-0 text-5 text-black- fw-500">
					{{ file?.name }}
				</h6>
			</div>
			<div class="ms-auto">
				<i
					v-if="!aborted && !deleted"
					@click="cancelOrDelete"
					role="button"
					class="uil uil-trash text-red- fs-4"
				></i>
				<span class="text-red-" v-if="aborted">Annulé</span>
				<span class="text-red-" v-if="deleted">Supprimé</span>
			</div>
		</div>
		<div class="col-3 p-1 ps-3 hstack">
			<span class="text-black- fw-600 text-5">{{
				sizeConverter(file?.size)
			}}</span>
			<i
				type="button"
				v-if="status == 0"
				class="uil uil-check-circle ms-auto fs-4 text-alt-"
			></i>
			<i
				type="button"
				v-if="status == 2"
				class="uil uil-times-circle ms-auto fs-4 text-red-"
			></i>
			<div
				v-if="status == 1"
				class="spinner-border ms-auto text-primary"
				role="status"
			>
				<span class="visually-hidden">Loading...</span>
			</div>
			<i
				type="button"
				v-if="status == -1"
				@click="uploadFile()"
				class="uil uil-redo ms-auto fs-4 text-alt-"
			></i>
		</div>
	</div>
</template>
<script>
import Helper from '@/helpers/helper';
export default {
	name: 'FileUpload',
	props: {
		file: {
			default: null,
		},
		folder: {
			default: 1,
		},
	},
	emits: ['uploaded', 'deleted'],
	data() {
		return {
			url: '',
			status: 1,
			ok: true,
			uploaded: null,
			controller: new AbortController(),
			aborted: false,
			deleted: false,
		};
	},
	methods: {
		sizeConverter(bytes) {
			return Helper.sizeConverter(bytes);
		},
		cancelOrDelete() {
			if (this.uploaded) {
				this.deleteUploaded();
			} else {
				this.abortUpload();
			}
		},
		abortUpload() {
			this.aborted = true;
			this.status = 2;
			this.controller.abort('cancelled');
		},
		deleteUploaded() {
			this.$isLoading(true);
			let body = {
				ids: [this.uploaded.id],
			};
			fetch(
				Helper.route('admin/document/delete'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => response.json())
				.then((data) => {
					console.log('Arigoooooooooo', data);
					this.$isLoading(false);

					if (data.problems) {
						this.deleted = false;
						this.status = 0;
						Helper.notification('error', data.message);
					} else {
						this.deleted = true;
						this.status = 2;
						this.$emit('deleted', this.uploaded);
						Helper.notification('success', data.message);
					}
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		uploadFile() {
			this.status = 1;
			this.ok = true;
			let registerFormData = new FormData();
			registerFormData.append('document', this.file);
			registerFormData.append('folder', this.folder);

			console.log('folder ', this.folder)

			fetch(
				Helper.route('admin/document/add'),
				Helper.requestOptions('POST', registerFormData, this.controller.signal)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					console.log(data);
					this.check_form = false;

					if (data.problems) {
						this.status = -1;
					} else {
						if (!this.ok) {
							this.status = -1;
						} else {
							if (data.status) {
								this.status = 0;
								this.message = data.message;
								this.uploaded = data.data[0];
								this.$emit('uploaded', data.data[0]);
							}
						}
					}
				})
				.catch((error) => {
					// this.status = -1;
					console.log(error);
				});
		},
	},

	created() {
		this.uploadFile();
		this.url = Helper.route('admin/document/add');
	},
};
</script>
<style></style>
