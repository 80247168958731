<template>
	<div>
		<Template
			:title="$t('programs.saling.issue_invoice')"
			sidebarMenu="program-managements"
		>
			<!-- Modal -->
			<div
				class="modal fade"
				id="deleteModal"
				data-mdb-backdrop="static"
				data-mdb-keyboard="false"
				tabindex="-1"
				aria-labelledby="deleteModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content rounded-0">
						<div class="modal-body">
							<h6 class="text-center text-red-">
								{{
									IS_UPDATE_CTX
										? $t('programs.saling.update_item')
										: $t('programs.saling.add_item')
								}}
							</h6>
							<hr />
							<div class="text-primary- py-3" v-if="message.length > 0">
								{{ message }}
							</div>
							<InputFC
								:type="'text'"
								class="mb-4"
								v-model="article.name"
								:placeholder="$t('programs.saling.item')"
							/>
							<div class="row">
								<InputFC
									:type="'number'"
									class="col-lg-6 mb-4"
									min="1"
									v-model="article.quantity"
									:placeholder="$t('programs.saling.quantity')"
								/>
								<InputFC
									:type="'number'"
									class="col-lg-6 mb-4"
									min="1"
									v-model="article.unitPrice"
									:placeholder="$t('programs.saling.unit_price')"
								/>
							</div>
							<hr />
							<InputFC
								:type="'select'"
								class="mb-4"
								:options="boolean_choices"
								v-model="has_tva"
								:placeholder="'TVA'"
							/>
							<hr />
							<div class="col-10 mx-auto">
								<table
									class="table table-striped table-bordered"
									style="width: 100% !important"
								>
									<tr>
										<td class="border px-3 text-center">
											{{ $t('programs.amount_excluding_tax') }}
										</td>
										<td class="text-primary- px-3 text-center border">
											{{ $filters.formatAmount(article.priceHT) }}
										</td>
									</tr>
									<tr>
										<td class="border px-3 text-center">TVA</td>
										<td class="text-primary- px-3 text-center border">
											{{ $filters.formatAmount(article.tva) }}
										</td>
									</tr>
									<tr>
										<td class="border px-3 text-center">
											{{ $t('programs.amount_including_tax') }}
										</td>
										<td class="text-primary- px-3 text-center border">
											{{ $filters.formatAmount(article.priceTTC) }}
										</td>
									</tr>
								</table>
							</div>
							<hr />
							<div class="row">
								<div class="col text-center">
									<button
										type="button"
										class="border bg-light px-3 text-5 rounded py-1"
										data-mdb-dismiss="modal"
									>
										{{ $t('global.close') }}
									</button>
								</div>
								<div class="col text-center">
									{{ get_tva(article.quantity, article.unitPrice, has_tva) }}
									<button
										v-if="!IS_UPDATE_CTX"
										type="button"
										class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
										@click="save_article(article)"
									>
										{{ $t('global.add') }}
									</button>
									<button
										v-if="IS_UPDATE_CTX"
										type="button"
										data-mdb-dismiss="modal"
										class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
										@click="update_article()"
									>
										{{ $t('global.update') }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section class="p-5 py-4">
				<Back />

				<div class="row">
					<div class="col-xl-9">
						<div class="bg-second- fw-1000 shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 py-1 mt-3">
							{{ $t('programs.saling.issue_invoice') }}
						</div>

						<div class="bg-light-hight- rounded pb-5 mt-4">
							<form
								@submit.prevent="saleNew"
								class="col-lg-10 col-md-11 mx-auto"
							>
								<hr />
								<p class="text-center">
									{{ $t('programs.saling.invoice_to') }}
								</p>
								<textarea v-model="customer" required class="form-control-" rows="6" placeholder="Nom et Prénom(s)&#10;Email&#10;Adresse&#10;IFU&#10;RCCM &#10;Téléphone"></textarea>

								<hr />
								<p class="text-center">Livré à (Facultatif)</p>
								<textarea v-model="recipient" class="form-control-" rows="6" placeholder="Nom et Prénom(s)&#10;Email&#10;Adresse&#10;IFU&#10;RCCM &#10;Téléphone"></textarea>

								<hr />

								<table class="table table" style="width: 100% !important">
									<thead class="bg-primary- table-bordered border">
										<tr>
											<th class="text-white py-2">Article</th>
											<th class="text-white py-2">Qte</th>
											<th class="text-white py-2">PU</th>
											<th class="text-white py-2">HT</th>
											<th class="text-white py-2">TVA</th>
											<th class="text-white py-2">TTC</th>
											<th class="text-white py-2">{{ $t('global.action') }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(article, i) in articles" :key="article.id">
											<td>{{ article.name }}</td>
											<td>
												{{ $filters.formatAmount(article.quantity, false) }}
											</td>
											<td>
												{{ $filters.formatAmount(article.unitPrice, false) }}
											</td>
											<td>
												{{ $filters.formatAmount(article.priceHT, false) }}
											</td>
											<td>{{ $filters.formatAmount(article.tva, false) }}</td>
											<td>
												{{ $filters.formatAmount(article.priceTTC, false) }}
											</td>
											<td>
												<i
													data-mdb-toggle="modal"
													data-mdb-target="#deleteModal"
													role="button"
													@click="edit_article(i)"
													class="uil uil-pen text-4 px-1 text-alt-"
												></i>
												<i
													@click="remove_article(i)"
													role="button"
													class="uil uil-trash text-4 text-red-"
												></i>
											</td>
										</tr>
									</tbody>
								</table>

								<hr />
								<table class="table" style="width: 100% !important">
									<tr>
										<td class="bg-dark px-3 border text-white- text-center">
											{{ $t('programs.amount_excluding_tax') }}
										</td>
										<td class="border text-black- bg-white text-center px-4">
											{{ $filters.formatAmount(amountHT) }}
										</td>
									</tr>
									<tr>
										<td class="bg-dark px-3 border text-white- text-center">
											TVA
										</td>
										<td class="border text-black- bg-white text-center px-4">
											{{ $filters.formatAmount(tva) }}
										</td>
									</tr>
									<tr>
										<td class="bg-dark px-3 border text-white- text-center">
											{{ $t('programs.amount_including_tax') }}
										</td>
										<td class="border text-black- bg-white text-center px-4">
											{{ $filters.formatAmount(amountTTC) }}
										</td>
									</tr>
								</table>

								<div class="text-center">
									<span
										class="button btn-sm bg-alt-"
										type="button"
										data-mdb-toggle="modal"
										data-mdb-target="#deleteModal"
									>
										{{ $t('programs.saling.add_item') }}
									</span>
								</div>

								<hr />

								<InputFC
									:type="'select'"
									class="mb-4"
									:options="payment_methods"
									v-model="paymentMean"
									:placeholder="$t('programs.buying.payment_method')"
									:required="true"
								/>

								<!-- <InputFC
									:error="errors.date"
									class="mb-4"
									:type="'datetime-local'"
									v-model="date"
									:placeholder="'Date d\'émission'"
								/> -->

								<div class="hstack">
									<div class="">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" class="button px-4 py-1 mt-4">
											{{ $t('global.send') }}
										</button>
									</div>
									<div class="ms-3">
										<button
											class="button-default bg-light- text-color- fw-500 shadow-sm px-4 py-1 mt-4"
											@click="$router.go(-1)"
										>
											{{ $t('global.cancel') }}
										</button>
									</div>
								</div>
							</form>
						</div>

						<div class="col-xl-3"></div>
					</div>

					<div class="col-xl-3 mt-3">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							{{ $t('programs.saling.create_advice_text') }}
						</div>
						<router-link to="/documents/create"
							><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
								{{ $t('global.import_now') }}
							</button>
						</router-link>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';

export default {
	name: 'CreateDocument',
	components: { Template, Back, InputFC, SpinnerMe },
	data() {
		return {
			message: '',
			customer: '',
			recipient: '',
			article: {},
			articles: [],
			amountHT: 0,
			tva: 0,
			amountTTC: 0,
			paymentMean: '',
			has_tva: false,
			date: '',
			payment_methods: [],
			boolean_choices: [],
			errors: [],
			IS_UPDATE_CTX: false,
			UPDATE_ART_ID: 0,
		};
	},
	methods: {
		saleNew() {
			if (this.articles.length > 0) {
				this.ok = true;
				this.error_message = '';
				Helper.loading('submit', 'spinner', true);

				let body = {
					customer: this.customer,
					recipient: this.recipient,
					articles: this.articles,
					amountHT: this.amountHT,
					tva: this.tva,
					amountTTC: this.amountTTC,
					paymentMean: this.paymentMean,
				};

				fetch(
					Helper.route('admin/sale/add'),
					Helper.requestOptionsJSON('POST', body)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						return response.json();
					})
					.then((data) => {
						console.log(data);
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.customer = Helper.getError(
								data.problems,
								'customer'
							);
							this.errors.recipient = Helper.getError(
								data.problems,
								'recipient'
							);
							this.errors.amountHT = Helper.getError(
								data.problems,
								'amountHT'
							);
							this.errors.tva = Helper.getError(data.problems, 'tva');
							this.errors.amountTTC = Helper.getError(
								data.problems,
								'amountTTC'
							);
							this.errors.paymentMean = Helper.getError(
								data.problems,
								'paymentMean'
							);
							// this.errors.date = Helper.getError(data.problems, 'date');
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								Helper.notification('error', data.message);
							} else {
								if (data.status) {
									Helper.notification('success', data.message);
									this.$router.go(-1);
								} else {
									Helper.notification('error', data.message);
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
				
			} else {
				Helper.notification('error', 'Veuillez renseigner au moins un article');
			}
		},
		get_tva(quantity, unitPrice, has_tva = 'false') {
			let tva =
				unitPrice && quantity && has_tva == 'true'
					? unitPrice * quantity * 0.18
					: 0;
			this.article.priceHT = unitPrice * quantity;
			this.article.priceTTC = this.article.priceHT + tva;
			this.article.tva = tva;
		},
		save_article() {
			let article = this.article;
			if (
				article.name &&
				article.name.length > 0 &&
				parseInt(article.unitPrice) > 0 &&
				parseInt(article.quantity) > 0
			) {
				article.id = this.articles.length;

				this.articles.push(this.article);

				this.article = {};
				this.message = 'Article ajouté avec succès';

				this.reloadAmount();
			}
		},
		update_article() {
			let article = this.article;

			if (
				article.name &&
				article.name.length > 0 &&
				parseInt(article.unitPrice) > 0 &&
				parseInt(article.quantity) > 0
			) {
				article.id = this.articles.length;

				this.articles[this.UPDATE_ART_ID] = this.article;

				this.article = {};
				this.message = 'Article modifié avec succès';

				Helper.notification('success', 'Article modifié avec succès');

				this.IS_UPDATE_CTX = false;

				this.reloadAmount();
			}
		},
		edit_article(i) {
			this.article = this.articles[i];
			this.UPDATE_ART_ID = i;
			this.IS_UPDATE_CTX = true;
		},
		remove_article(index) {
			this.articles.splice(index, 1);
			this.reloadAmount();
		},
		reloadAmount() {
			this.amountHT = 0;
			this.amountTTC = 0;
			this.tva = 0;
			for (let i = 0; i < this.articles.length; i++) {
				const article = this.articles[i];

				this.amountHT = parseInt(this.amountHT) + parseInt(article.priceHT);
				this.amountTTC = parseInt(this.amountTTC) + parseInt(article.priceTTC);
				this.tva = parseInt(this.tva) + parseInt(article.tva);
			}
		},
	},
	created() {
		this.url = Helper.route('admin/document/add');
		this.payment_methods = Helper.payment_methods;
		this.boolean_choices = Helper.boolean_choices;
	},
};
</script>
<style></style>
