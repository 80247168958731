<template>
	<div>
		<Template title="null" sidebarMenu="program-managements">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/program-managements/sale"
					class="bg-light-hight- ms-2 text-6 top-item"
					>{{ $t('programs.saling.sale') }}</router-link
				>
				<router-link
					to="/program-managements/buying"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('programs.buying.buy') }}</router-link
				>
				<router-link
					to="/program-managements/sale-archives"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('global.archive') }}</router-link
				>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Archivage -->
				<div
					class="modal fade"
					id="archiveModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="archiveModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-alt-">
									{{ $t('global.archiving_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!archived" v-html="$t('programs.saling.archive_warning_text')">
									
								</p>
								<p class="text-5 text-center" v-show="archived">
									{{ $t('global.archiving_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div
										class="col text-center"
										v-show="!archived && selectedList.length !== 0"
									>
										<button
											type="button"
											class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="archives()"
										>
											{{ $t('global.archive') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div v-if="!loading && recents.length != 0">
						<div
							class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
						>
							{{ $t('global.recents') }}
						</div>
						<div class="row mt-1 g-2 mb-4">
							<div class="col-3" v-for="(sale, index) in recents" :key="index">
								<div class="rate_item bg-light-hight-1 rounded">
									<div class="hstack w-75 mx-auto p-3">
										<div class="text-center w-75 mx-auto p-3">
											<img src="@/assets/images/sale.png" alt="" />
										</div>
									</div>
									<div
										class="bg-second- text-center text-truncate text-white rounded p-2 text-5"
									>
										<router-link
											:to="{
												name: 'sales-show',
												params: { id: sale.id, slug: sale.slug },
											}"
										>
											{{ sale.number }}
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="hstack mt-0">
						<router-link
							to="/program-managements/sale/create"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('programs.saling.issue_invoice')
							}}</span>
						</router-link>
					</div>

					<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
						<input
							type="checkbox"
							:checked="allIsCheck"
							@click="check(0, true)"
						/>
						<i
							class="uil uil-archive ms-3 fs-6"
							type="button"
							data-mdb-toggle="modal"
							data-mdb-target="#archiveModal"
							@click="archived = false"
						></i>

						<div class="hstack ms-auto">
							<label for="" class="text-5 me-2">{{
								$t('global.filter')
							}}</label>
							<select class="form-control text-6" v-model="orderBy">
								<option value="id">{{ $t('global.order_of_addition') }}</option>
								<option value="number">{{ $t('global.number') }}</option>
								<option value="tva">TVA</option>
								<option value="amountTTC">
									{{ $t('programs.amount_including_tax') }}
								</option>
								<option value="amountHT">
									{{ $t('programs.amount_excluding_tax') }}
								</option>
								<option value="date">{{ $t('global.date') }}</option>
							</select>

							<label for="" class="text-5 ms-2 me-2">{{
								$t('global.order')
							}}</label>
							<select class="form-control text-6" v-model="order">
								<option value="ASC">Asc</option>
								<option value="DESC">Desc</option>
							</select>
						</div>

						<div class="ms-auto hstack">
							<i
								class="uil uil-arrow-circle-left fs-5"
								type="button"
								@click="previous"
							></i>
							<small class="ms-3"
								>{{ Math.ceil(sales.length / 25) > 0 ? page : 0 }} /
								{{ Math.ceil(sales.length / 25) }}</small
							>
							<i
								class="uil uil-arrow-circle-right fs-5 ms-3"
								type="button"
								@click="next"
							></i>
							<div class="hstack ms-5">
								<i
									class="uil uil-apps fs-6 p-1 px-3 rounded"
									:class="
										store.disposition == 'grid'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('grid')"
									type="button"
								></i>
								<i
									class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
									:class="
										store.disposition == 'list'
											? 'bg-black- text-white-'
											: 'bg-white- text-black-'
									"
									@click="store.setDisposition('list')"
									type="button"
								></i>
							</div>
						</div>
					</div>

					<LoadingMain :state="loading" />

					<div v-if="!loading && sales.length != 0">
						<div
							class="table-responsive mt-0 mb-4 "
							v-if="store.disposition == 'list'"
						>
							<table
								class="table sales align-middle table-hover table-striped mb-0" style="width: 60% !important;">
								<thead class="bg-primary- text-center">
									<tr class="text-6 text-white">
										<th></th>
										<th>{{ $t('programs.buying.invoice') }}</th>
										<th>{{ $t('programs.saling.invoice_to') }}</th>
										<th>{{ $t('programs.buying.mode') }}</th>
										<th>{{ $t('global.date') }}</th>
										<th>{{ $t('global.amount') }}</th>
										<th>TVA</th>
										<th>{{ $t('programs.amount_including_tax') }}</th>
									</tr>
								</thead>
								<tbody class="bg-alt- text-center">
									<tr
										class="text-6 text-black"
										v-for="(sale, index) in sales"
										:key="index"
										v-show="show(index)"
									>
										<td>
											<input
												type="checkbox"
												:checked="isSelected(sale.id)"
												@click="check(sale.id)"
											/>
										</td>
										<td>
											<router-link
												:to="{
													name: 'sales-show',
													params: { id: sale.id, slug: sale.slug },
												}"
											>
												{{ sale.number }}
											</router-link>
										</td>
										<td>{{ sale.customer.fullname }}</td>
										<td>{{ sale.paymentMean }}</td>
										<td>{{ $filters.convertDate(sale.createdAt) }}</td>
										<td>{{ $filters.formatAmount(sale.amountHT, false) }}</td>
										<td>{{ $filters.formatAmount(sale.tva, false) }}</td>
										<td>{{ $filters.formatAmount(sale.amountTTC, false) }}</td>
									</tr>
									<tr>
										<td colspan="5" class="bg-second-">
											{{ $t('programs.buying.invoice') }}
										</td>
										<td
											class="bg-black border-1 py-2 px-4 text-white- fw-600 text-6"
										>
											{{ $filters.formatAmount(stats.amountHT, false) }}
										</td>
										<td
											class="bg-black border-1 py-2 px-4 text-white- fw-600 text-6"
										>
											{{ $filters.formatAmount(stats.tva, false) }}
										</td>
										<td
											class="bg-black border-1 py-2 px-4 text-white- fw-600 text-6"
										>
											{{ $filters.formatAmount(stats.amountTTC, false) }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="row mt-1 g-2" v-else>
							<div
								class="col-md-3"
								v-for="(sale, index) in sales"
								:key="index"
								v-show="show(index)"
							>
								<div class="rate_item bg-light-hight-1 rounded">
									<div class="text-center w-75 mx-auto p-3">
										<img src="@/assets/images/sale.png" alt="" />
									</div>
									<div
										class="bg-second- text-center text-truncate text-white rounded p-2 text-5"
									>
										<input
											class="me-2"
											type="checkbox"
											:checked="isSelected(sale.id)"
											@click="check(sale.id)"
										/>
										<router-link
											:to="{
												name: 'sales-show',
												params: { id: sale.id, slug: sale.slug },
											}"
										>
											{{ sale.number }}
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						class="py-4 mt-1 text-center bg-white- text-5"
						v-if="!loading && sales.length == 0"
					>
						{{ $t('global.no_data') }}
					</div>
				</div>

				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						v-html="$t('programs.saling.index_advice_text')"
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
					></div>
					<router-link to="/documents/create"
						><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
							{{ $t('global.import_now') }}
						</button>
					</router-link>

					<div class="" style="margin-top: 110px">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.today_summary') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							<div class="row px-3">
								<div class="col-6 text-start py-1">
									{{ $t('programs.buying.invoice') }}:
								</div>
								<div class="col-6 text-alt- text-end py-1">
									{{ $filters.formatAmount(stats.totalSales, false) }}
								</div>
								<div class="col-6 text-start py-1">
									{{ $t('programs.amount_excluding_tax') }}:
								</div>
								<div class="col-6 text-alt- text-end py-1">
									{{ $filters.formatAmount(stats.amountHT) }}
								</div>
								<div class="col-6 text-start py-1">TVA:</div>
								<div class="col-6 text-alt- text-end py-1">
									{{ $filters.formatAmount(stats.tva) }}
								</div>
								<div class="col-6 text-start py-1">
									{{ $t('programs.amount_including_tax') }}:
								</div>
								<div class="col-6 text-alt- text-end py-1">
									{{ $filters.formatAmount(stats.amountTTC) }}
								</div>
							</div>
						</div>
						<div class="bg-alt- mt-1 p-1 rounded text-center">
							<span class="text-6 text-white-">TAGS</span>
							<p class="text-6 p-0 m-0 text-yellow-">
								#Vente #Chiffre #Affaire #Client
							</p>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import LoadingMain from '@/components/LoadingMain.vue';
import Helper from '@/helpers/helper';
import { useDispositionStore } from '@/store/disposition';

export default {
	setup() {
		const store = useDispositionStore();
		return { store };
	},
	name: 'HomePage',
	components: { Template, LoadingMain },
	data() {
		return {
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 25,
			deleted: false,
			archived: false,
			loading: false,
			disposition: 'list',
			orderBy: 'id',
			order: 'DESC',
			recents: [],
			sales: [],
			stats: {
				tva: 0,
				amountHT: 0,
				amountTTC: 0,
				totalSales: 0,
			},
		};
	},
	methods: {
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.sales.length) {
					this.selectedList.length = 0;
					this.sales.forEach((employee) => {
						this.selectedList.push({ id: employee.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.sales.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.sales.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/sale/all?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					if (data.sales) {
						this.sales = data.sales;
						this.recents = data.recents;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadStats() {
			this.loading = true;
			fetch(Helper.route('admin/sale/stats'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					this.stats = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		archives() {
			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
			};
			fetch(
				Helper.route('admin/sale/archive'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);
					this.archived = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						this.sales = this.sales.filter((i) => i.id !== element.id);
						this.recents = this.recents.filter((i) => i.id !== element.id);
					}
					this.selectedList = [];
					Helper.notification('success', data.message);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
		this.loadStats();
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
	},
};
</script>
<style>
.rate_item img {
	height: 70px;
	width: 70px;
	object-fit: cover;
}
.sales th,
.sales td {
	color: white !important;
}
.employe_item img {
	height: 100px;
	width: 100px;
}
</style>
